@import 'react-quill/dist/quill.snow.css';
.white {
  color: #ffffff !important;
}

.white-svg path {
  stroke: #ffffff !important;
}

.ql-toolbar.ql-snow {
  border-color: #d9d9d9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container.ql-snow {
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ql-editor {
  background: #fff;
  min-height: 500px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.span-coma {
  span:after {
    content: ', ';
  }

  span:last-child {
    &:after {
      content: '';
    }
  }
}
